<template lang="pug">
section.main
  //- aside.side-menu(v-if="$auth.loggedIn")
  aside.side-menu
    side-menu
  main.core
    //- active-team
    tab-menu(:tabs="items")
    slot
</template>

<script>
import SideMenu from "~/components/Sidemenu";
import TabMenu from "~/components/Tabmenu";
import ActiveTeam from "~/components/ActiveTeam";

export default {
  components: {
    SideMenu,
    TabMenu,
    ActiveTeam,
  },
  data() {
    return {
      items: [
        {
          label: "Library",
          link: "/media/",
        },
        // {
        //   label: 'Library',
        //   link: '/media/library',
        // },
        // {
        //   label: 'Builder',
        //   link: '/media/builder',
        // },
      ],
      activeItem: 1,
    };
  },
};
</script>
<style lang="scss">
section.main {
  overflow-x: hidden;
  display: flex;
  width: 100%;
  .side-menu {
    // flex: 1;
    width: rempx(91);
  }
  .core {
    flex: 1;
  }
}
</style>
